import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserRole } from '../../models/user-role';

import { BasePermissionService } from './base-permission.service';

/** App permission services. */
@Injectable({ providedIn: 'root' })
export class AppPermissionService extends BasePermissionService {

	/**
	 * Whether user can access field app.
	 * @param role Role.
	 */
	public canAccessFieldApp$(): Observable<boolean> {
		return this.checkUserRole([UserRole.Foreman]);
	}

	/**
	 * Whether user can access field app.
	 * @param role Role.
	 */
	public canAccessBackOfficeApp$(): Observable<boolean> {
		return this.checkUserRole([UserRole.Admin, UserRole.SuperAdmin, UserRole.ProjectManager]);
	}
}
